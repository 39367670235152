<template>
  <v-row no-gutters>
    <v-col cols="12" class="pl-3">
      <v-tabs class="acordos-tabs" active-class="activeMyClass" background-color="transparent">
        <v-tab :to="{ name: 'acordoPdv' }">PDV</v-tab>
        <v-tab v-if="usuarioTipo !== 'cubo'" :to="{ name: 'acordoEcommerce' }">E-commerce</v-tab>
        <v-tab :to="{ name: 'acordoCampanha' }">Campanha</v-tab>
        <v-tab v-if="usuarioTipo == 'rd'" :to="{ name: 'negociacaoComercial' }">Negociação comercial</v-tab>

      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>

export default {
  computed: {
    usuarioTipo() {
            const usuario = this.$store.getters.getUsuario;
            if (usuario.id_fornecedor) {
                return "fornecedor";
            }
            if(usuario.cargo == "Cubo"){
                return "cubo";
            }
            return "rd";
        },
  },
}

</script>

<style>
.acordos-tabs .activeMyClass:hover:before {
  background: none;
}

.acordos-tabs .v-tab:before {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.acordos-tabs .v-tabs-slider {
  width: 70%;
  margin: 0 15%;
}

.acordos-tabs .v-tab {
  /* width: 150px; */
  min-width: 150px;
  max-width: 250px;
  font-size: 1.2rem;
  font-weight: normal !important;
  letter-spacing: 0px;
  color: #999 !important;
  text-transform: none !important;
}

.acordos-tabs .activeMyClass {
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #000 !important;
}
</style>
